jQuery(($) => {

    let quoteButtons    = $('.btn--quote');
    let designOption    = $('input[name="design_option"]');
    let sideOneUpload   = $('#upload--image--side-one');
    let sideTwoUpload   = $('#upload--image--side-two');
    let sideOneUploadInput   = $('#upload--image--side-one input[type="file"]');
    let sideTwoUploadInput   = $('#upload--image--side-two input[type="file"]');
    let sideUploadText       = $('#upload--image--description');


    quoteButtons.each( (index, element)=> {
        $(element).on('click', (event) => {
            event.preventDefault();
        })
    })

    /**
     * Set design options button to disabled on page load
     */
    designOption.attr('disabled', true);

    /**
     * Conditionally setting required values on design options radio buttons.
     */
     $(document).on('change', '#pa_salesforce_sided__c', () => {
        let sidedOptionVal  = $('input[name="attribute_pa_sided__c"]:checked').val();

        if ((sidedOptionVal == 'Single Sided') || (sidedOptionVal == 'Double Sided')) {
            designOption.attr('required', true);
        } else {
            designOption.attr('required', false);
        }
    })
    
    /**
     * Conditionally changes displayed upload buttons when Artwork Upload option is selected.
     */
    $(document).on('change', 'input[name="design_option"]', (event) => {
        event.preventDefault();

        let sidedOptionVal  = $('input[name="attribute_pa_sided__c"]:checked').val();
        let designOptionVal = $('input[name="design_option"]:checked').val();

        if (designOptionVal == 'Design Upload' && sidedOptionVal == 'Double Sided') {
            sideUploadText.removeClass('d-none');
            sideOneUpload.removeClass('d-none');
            sideTwoUpload.removeClass('d-none');
            sideOneUploadInput.attr('required', true);
            sideTwoUploadInput.attr('required', true);

        } else if (designOptionVal == 'Design Upload' && sidedOptionVal == 'Single Sided'){
            sideUploadText.removeClass('d-none');
            sideOneUpload.removeClass('d-none');
            sideTwoUpload.addClass('d-none');
            sideOneUploadInput.attr('required', true);
            sideTwoUploadInput.attr('required', false);

        } else {
            sideUploadText.addClass('d-none');
            sideOneUpload.addClass('d-none');
            sideTwoUpload.addClass('d-none');
            sideOneUploadInput.attr('required', false);
            sideTwoUploadInput.attr('required', false);
        }
    })
    
    /**
     * Conditionally changes displayed upload buttons when Sided option is selected.
     */
    $(document).on('change', '#pa_salesforce_sided__c', () => {
        let sidedOptionVal  = $('input[name="attribute_pa_sided__c"]:checked').val();
        let designOptionVal = $('input[name="design_option"]:checked').val();

        if (designOptionVal == 'Design Upload' && sidedOptionVal == 'Double Sided') {
            designOption.attr('disabled', false);
            sideOneUpload.removeClass('d-none');
            sideTwoUpload.removeClass('d-none');
            sideOneUploadInput.attr('required', true);
            sideTwoUploadInput.attr('required', true);

        } else if (designOptionVal == 'Design Upload' && sidedOptionVal == 'Single Sided'){
            designOption.attr('disabled', false);
            sideOneUpload.removeClass('d-none');
            sideTwoUpload.addClass('d-none');
            sideOneUploadInput.attr('required', true);
            sideTwoUploadInput.attr('required', false);

        } else if (sidedOptionVal == 'Double Sided') {
            designOption.attr('disabled', false);

        } else if (sidedOptionVal == 'Single Sided'){
            designOption.attr('disabled', false);

        } else {
            designOption.attr('checked', false).attr('disabled', true);
            sideOneUpload.addClass('d-none');
            sideTwoUpload.addClass('d-none');
            sideOneUploadInput.attr('required', false);
            sideTwoUploadInput.attr('required', false);
            sideOneUploadInput.val('').next('.upload--success').remove();
            sideTwoUploadInput.val('').next('.upload--success').remove();
        }
    })

    /**
     * Type Check and append success/fail message after image is added to upload button.
     */
    $(document).on('change', '#upload--image--side-one, #upload--image--side-two, input[type="file"]',  (event) => {
        
        const filetypes = [
            'image/gif',
            'image/png',
            'image/jpeg',
            'image/tiff',
            'application/zip',
            'application/x-gzip',
            'application/pdf'
        ];
        
        if ((event.target.files.length > 0) && filetypes.includes(event.target.files[0].type) && (event.target.files[0].size < 10000000)) {
            $(event.target).siblings('.upload--failed').remove();
            $(event.target).next('.upload--success').remove();
            $(event.target).after('<p class="upload--success">Upload Successful</p>');
        } else if ((event.target.files[0].size > 10000000)) {
            $(event.target).val('');
            $(event.target).siblings('.upload--failed').remove();
            $(event.target).next('.upload--success').remove();
            $(event.target).after(`<p class="upload--failed">Whoops! It looks like you're trying to upload an image larger than the 10 MB size limit. Please try reducing it.</p>`);          
        } else {
            $(event.target).val('');
            $(event.target).siblings('.upload--failed').remove();
            $(event.target).next('.upload--success').remove();
            $(event.target).after(`<p class="upload--failed">Whoops! It looks like you're trying to upload an unsupported file type.</p>`);
        }
    })

    /**
     * Clear :checked from custom variation radio buttons.
     */
    $(document).on('click', '.reset_variations', (event) => {
        let radioButtons = $(event.target).closest('.variations_form.cart').find('input:radio');
        
        radioButtons.each( (index, element) => {
            $(element).prop('checked', false);
        })

        sideOneUploadInput.val('').next('.upload--success').remove();
        sideTwoUploadInput.val('').next('.upload--success').remove();
        designOption.attr('checked', false).attr('required', false).attr('disabled', true);
    });
})

