import 'owl.carousel2'

jQuery(($) => {

  const CAROUSEL_ARGS = {
      Benefits: {
        margin: 10,
        nav: false,
        dots: true,
        smartSpeed: 1000,
        navText: [
         '<span class="sr-only">Next Item</span>',
         '<span class="sr-only">Previous Item</span>'
        ],
        responsive : {
            0 : {
                items: 1,
                center: true,
                loop: true,
            },
            768 : {
                items: 2,
                loop: true,
            },
            992 : {
                items: 3,
                loop: true,
            }
        }
      },
      Comparison: {
        margin: 10,
        nav: false,
        dots: true,
        smartSpeed: 1000,
        navText: [
         '<span class="sr-only">Next Item</span>',
         '<span class="sr-only">Previous Item</span>'
        ],
        responsive : {
            0 : {
                items: 1,
                center: true,
                loop: false
            },
            768 : {
                items: 2,
                loop: false
            }
        },
        onChanged: disableStartEndNavigation
      },
      DesignGallery: {
        margin: 10,
        nav: true,
        dots: true,
        dotsData: true,
        smartSpeed: 1000,
        navText: [
         '<span class="sr-only">Next Item</span>',
         '<span class="sr-only">Previous Item</span>'
        ],
        responsive : {
            0 : {
                items: 1,
                center: true,
                loop: false
            }
        },
        onChanged: disableStartEndNavigation
      },
      Gallery: {
        nav: false,
        dots: true,
        items: 1,
        smartSpeed: 1000,
        navText: [
          '<span class="sr-only">Next Item</span>',
          '<span class="sr-only">Previous Item</span>'
        ],
        responsive : {
            0 : {
                items: 1,
                dotsEach: 4,
                stagePadding: 20,
                margin: 15,
            },
            768 : {
                items: 2,
                stagePadding: 30,
                margin: 15,
            },
            992 : {
                items: 3,
                stagePadding: 30,
                margin: 20,
            },
            1200 : {
                items: 4,
                stagePadding: 0,
                margin: 20
            }
        },
        onTranslate: updateScrubber,
        onRefreshed: updateScrubber,
        onChanged: disableStartEndNavigation
      },
      Product: {
        nav: false,
        dots: true,
        smartSpeed: 1000,
        navText: [
          '<span class="sr-only">Next Item</span>',
          '<span class="sr-only">Previous Item</span>'
        ],
        responsive : {
            0 : {
                items: 1,
                stagePadding: 20,
                margin: 15,
            },
            768 : {
                items: 2,
                stagePadding: 30,
                margin: 15,
            },
            992 : {
                items: 3,
                dotsEach: 1,
                stagePadding: 30,
                margin: 20,
            },
            1200 : {
                items: 4,
                stagePadding: 0,
                margin: 20
            }
        },
        onTranslate: updateScrubber,
        onRefreshed: updateScrubber,
        onChanged: disableStartEndNavigation
      }

  }
  
  function disableStartEndNavigation(event) {
    let $carousel           = $(event.target)
    let itemIndex           = event.item.index;
    let $itemsCount         = $carousel.find('.owl-item').length;
    let $itemsActiveCount   = $carousel.find('.owl-item.active').length;
    let $carouselNext       = $(`.carousel__nav-container[data-parent="#${$carousel.attr('id')}"] .owl-next`);
    let $carouselPrev       = $(`.carousel__nav-container[data-parent="#${$carousel.attr('id')}"] .owl-prev`);
    
    if (itemIndex < 1) {
        //console.log('awesome start!');
        $carouselPrev.addClass('disabled')
    } else if (itemIndex >= 1) {
        $carouselPrev.removeClass('disabled')
    }

    if (itemIndex == ($itemsCount - $itemsActiveCount)) {
        //console.log('awesome end!');
        $carouselNext.addClass('disabled')
    } else {
        $carouselNext.removeClass('disabled')
    }
  }


  function updateScrubber(event) {
    const $carousel = $(event.target)
    const $scrubber = $(`.carousel__scrubber[data-parent="#${$carousel.attr('id')}"] .scrubber`)
    if ($scrubber.length <= 0) {
      console.warn("no carousel scrubber found")
      return
    }
    const pagesCount = event.page.count
    const scrubberWidth = Math.round(100/pagesCount)
    const scrubberOffset = Math.round(scrubberWidth * event.page.index)
    const carouselCssArgs = {
        width: `${scrubberWidth}%`,
        left: `${scrubberOffset}%`
    }
    $scrubber.css(carouselCssArgs)
  }

  function getBreakpoint(breakpoint) {
    let property = `--breakpoint-${breakpoint}`
    return parseInt(getComputedStyle(document.body).getPropertyValue(property))
  }

  function getCarouselArgs(element) {
    if (element.hasClass('carousel--benefits')) {
      return CAROUSEL_ARGS.Benefits
    } else if (element.hasClass('carousel--gallery')) {
      return CAROUSEL_ARGS.Gallery
    } else if (element.hasClass('carousel--design-gallery')) {
      return CAROUSEL_ARGS.DesignGallery
    } else if (element.hasClass('carousel--comparison')) {
        return CAROUSEL_ARGS.Comparison
    } else if (element.hasClass('carousel--products')) {
        return CAROUSEL_ARGS.Product
    }
  }

  $('.owl-carousel').each(function () {
    let $carousel = $(this)
    let args = getCarouselArgs($carousel)
    let navContainer = `.carousel__nav-container[data-parent="#${$carousel.attr(
      'id'
    )}"]`
    args.dotsContainer = `${navContainer} .dots`
    if ($carousel.data('media-down')) {
      $(window).on('load resize', () => {
        let breakpoint = $carousel.data('media-down')
        if (window.innerWidth <= getBreakpoint(breakpoint)) {
          $carousel.owlCarousel(args)
        } else {
          $carousel.trigger('destroy.owl.carousel')
        }
      })
    } else {
      $carousel.owlCarousel(args)
    }
    $(navContainer).on('click', 'button', (event) => {
      let action = event.currentTarget.classList.contains('owl-next')
        ? 'next'
        : 'prev'
      $carousel.trigger(`${action}.owl.carousel`)
    })
  })
})
