jQuery(($) => {	
    let infoBubbleToggles = $('.button--info__toggle');
    let infoBubbleClose = $('.button--info__close');
    
    infoBubbleToggles.each( (index, element) => {
        $(element).on('click', (event) => {
            event.preventDefault();

            let button = $(event.target);
            let bubble = button.prev('.bubble--info');
            let bubbleClose = bubble.children('.button--info__close');
            
            bubble.toggleClass('d-none')

            ariaToggle(button);
            ariaToggle(bubbleClose);
        })
    })

    infoBubbleClose.each( (index, element) => {
        $(element).on('click', (event) => {
            event.preventDefault();

            let button = $(event.target);
            let bubble = button.parent('.bubble--info');
            let bubbleToggle = bubble.next('.button--info__toggle');

            bubble.toggleClass('d-none')
            
            ariaToggle(button);
            ariaToggle(bubbleToggle);
        })
    })

    function ariaToggle(element) {
        if (element.attr('aria-expanded') == 'false') {
            element.attr('aria-expanded', 'true');
        } else {
            element.attr('aria-expanded', 'false');
        }
    }  
})