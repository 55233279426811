jQuery(($) => {
  const NAV = {
    classes: {
      Scrolling: 'scrolling',
      Fixed: 'fixed-top',
      NavFixed: 'nav-fixed'
    },
    elements: {
      nav: jQuery('#navbar--primary'),
      header: jQuery('#header--site'),
      body: jQuery('body')
    },
    isScrolled: () => {
      let scrolled = false
      if ($(window).scrollTop() > NAV.elements.header.height()) {
        scrolled = true
      }
      return scrolled
    }
  }

  let $primaryNav = $('#navbar--primary')
  let $secondaryNav = $('#navbar--auxiliary')

  let $documentBody = $('body')

  $(window).on('load scroll', () => {
    let currentOffset = $(window).scrollTop()
    let navbarHeight = $primaryNav.height()
    let secondaryHeight = $secondaryNav.height()

    if (NAV.isScrolled()) {
      $primaryNav.addClass(NAV.classes.Scrolling)
      $secondaryNav.addClass(NAV.classes.Scrolling)

    }

    if (currentOffset > navbarHeight * 3) {
      $primaryNav.addClass('fixed-top')
      $secondaryNav.addClass('fixed-top')

      $documentBody.css('paddingTop', navbarHeight)
      $primaryNav.css('marginTop', secondaryHeight)
    } else if (currentOffset < navbarHeight) {
      $primaryNav.removeClass('fixed-top').removeClass('scrolling')
      $secondaryNav.removeClass('fixed-top').removeClass('scrolling')

      $documentBody.css('paddingTop', '')
      $primaryNav.css('marginTop', '')
    }
  })

  //Navigation drawer toggle
  let $drawerNavigation = $('.navbar--wrapper')
  let $drawerToggle = $('button.navbar-toggle')
  let $body = $('body')

  $drawerToggle.on('click', () => {
    $drawerNavigation.toggleClass('open')
    $body.toggleClass('nav-open')
  })
  $(window).on('resize', () => {
    if ($drawerNavigation.hasClass('open')) {
      $drawerNavigation.removeClass('open')
    }
    if ($body.hasClass('nav-open')) {
      $body.removeClass('nav-open')
    }
  })
  $(window).on('orientationchange', () => {
    if ($drawerNavigation.hasClass('open')) {
      $drawerNavigation.removeClass('open')
    }
    if ($body.hasClass('nav-open')) {
      $body.removeClass('nav-open')
    }
  })

  //Navigation dropdown navigation
  let $parentElements = $('.dropdown.nav-item')
  $parentElements.each((index, element) => {
    $(element).on('show.bs.dropdown hide.bs.dropdown', (e) => {
      let $dropdownMenu = $(element).find('ul.dropdown-menu')
      if (e.type == 'show') {

        let $dropdownMenuItems = $dropdownMenu.children()
        let $dropdownMenuHeight = 0

        $dropdownMenuItems.each(() => {
          $dropdownMenuHeight += parseInt($(element).outerHeight(), 10)
        })
        $dropdownMenu.css('height', $dropdownMenuHeight)
      } else {
        $dropdownMenu.css('height', 0)
      }
    })
  })
})