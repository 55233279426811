
jQuery(($) => {

    /**
     * SF Integration Object
     */
    const SF_Integration = {
        modals: {
            quote: $('#modal--quote'),
        },
        forms: {
            variation: $('form.variations_form.cart')
        },
        inputs: {
            productId: $('input[name="product_id"]'),
            variationId: $('input[name="variation_id"]')
        },
        buttons: {
            pricingMore: $('#pricing--more'),
        }
    }


    /**
     * Update hidden attribute dropdown values when radio buttons selected
     * then Run verification on form / send AJAX request on success
     * Change "product-price" to match name of pricing table input
     */
    let formElements = SF_Integration.forms.variation.find(':input').not('#pa_sleeve-size, #pa_salesforce_sided__c, #pa_salesforce_color__c, [name="product-price"],[name="quantity"], [name="order_quantity"], [name="add-to-cart"], [name="product_id"], [name="variation_id"], [name="design_option"], [name="image--side-one"], [name="image--side-two"], [name="attribute_pa_salesforce_color__c"] .single_add_to_cart_button');
    
    formElements.on('change', (event) => {
        $target         = $(event.target);
        $value          = $target.data('value');
        $selectInput    = $("#" + $(event.target).data('select'));
        
        if ($('input[name="attribute_pa_sided__c"]:checked').val() == 'Blank') {
            $('select[name="attribute_pa_salesforce_color__c"]').val('blank');
            $('input[name="attribute_pa_color__c"]').attr('disabled', 'disabled');
            $('input[name="attribute_pa_color__c"][value="Blank"]').attr('checked', 'checked');
        
        } else {
            $('input[name="attribute_pa_color__c"]').attr('disabled', false);
            $('input[name="attribute_pa_color__c"]').attr('checked', false);
        }
        
        $selectInput.val($value).trigger('change');
    });

    /**
     * Validate form for completion
     */
    function validateInputs(elements) {
        let validated = true;
        
        elements.each( (index, element) => {
            let name  = $(element).attr("name");
            
            if ($(element).attr('type') == 'radio' && $(element).attr('required') != null) {
                if($("input:radio[name="+name+"]:checked").length == 0) {
                    validated = false;
                }

            } else if ( $(element).attr('type') == 'file' && $(element).attr('required') != null )  {
                // console.log($(element));
                if ($(element).get(0).files.length == 0) {
                    validated = false;
                }
            } else if (  $(element).attr('type') != 'radio' )  {
                if ($(element).val == '') {
                    validated = false;
                }
            }
        });
        return validated;
    }
    
    /**
     * Run verification on form / send AJAX request on success to build pricing table
     */
    $('.single_variation_wrap').on('show_variation', (event, variation) => {
        if (validateInputs(formElements)) {
            // console.log(SF_Integration.inputs.productId.val());
            // console.log(SF_Integration.inputs.variationId.val());
            sendPricingRequest(formElements.serialize(), SF_Integration.inputs.variationId.val(), SF_Integration.inputs.productId.val());
        }
    });

    /**
     * Send pricing request
     */
    function sendPricingRequest(requestData, variationID, productID) {
        let pricingTableNode     = $('#pricing--table');
        
        $(document).ajaxStart($.blockUI({
            message: null,
            overlayCSS:  {
                backgroundColor: '#303640',
                opacity:         0.25,
                cursor:          'wait'
            },
        })).ajaxStop($.unblockUI);

        $.ajax({
            method: 'GET',
            url: TG.ajaxUrl,
            data: {
                action: 'product_pricing_request',
                requestProduct: parseInt(productID),
                requestVariation: parseInt(variationID),
                requestData: requestData,
                security: TG.nonce
            },
            success: (response) => {
                if (response.success == true) {
                    pricingTableNode.html(response.data)
                } else {
                    console.log('AJAX response error')
                }
            }
        })  
    }

    /**
     * Show More Pricing Button
     */
    $(document).on('click', '#pricing--more', (event) => {
        event.preventDefault();

        $(event.target).attr('aria-expanded', function(index, attr){
            return attr == 'true' ? 'false' : 'true';
        });

        $(event.target).siblings('.pricing--body').children('.custom-radio').toggleClass('open');
    })

    /**
     * Update hidden quantity fields with quantity chosen in the pricing list.
     */
    let priceListField = SF_Integration.forms.variation;
    let quantityField  = SF_Integration.forms.variation.find('.quantity .qty');
    let cartButton     = SF_Integration.forms.variation.find('.single_add_to_cart_button');
    
    priceListField.on('change', 'input[name="productPricing"]', (event) => {
        let pieceCountField  = SF_Integration.forms.variation.find('#order_quantity');
        let quantityValue   = parseInt($(event.target).data('quantity'));
        let pieceCountValue = parseInt($(event.target).data('case-count'));
        quantityField.val(quantityValue);
        pieceCountField.val(pieceCountValue);
    });


    // Ensures add to cart button stays deactivated when variations are discovered
    $(document.body).on('check_variations', () => {
        cartButton.attr('disabled', 'disabled').addClass('disabled');
    });


    // Activates add to cart button when form is complete.
    $(document).on('change', (event) => {
        let inputs = $('form.variations_form.cart').find(':input').not('.single_add_to_cart_button, [name="add-to-cart"], [name="product_id"], [name="variation_id"], [name="quantity"]');
        
        if (validateInputs(inputs)) {
            //console.log('Form Completed');
            cartButton.removeAttr('disabled').removeClass('disabled');
        } else {
            //console.log('Not Complete');
            cartButton.attr('disabled', true).addClass('disabled');
        }
    })
})
