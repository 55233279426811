import Isotope from 'isotope-layout'
import './_packery-mode.pkgd'

let gallery = document.querySelector('.design__gallery--grid');

if(typeof(gallery) != 'undefined' && gallery != null){
    // Initiate Isotope
    let iso = new Isotope( gallery, {
        layoutMode: 'packery',
        itemSelector: '.gallery__item',
    });


    // Bind filter button click
    let filtersElement = document.querySelector('.filters--list')

    if(typeof(filtersElement) != 'undefined' && filtersElement != null){
        filtersElement.addEventListener('click', (event) => {
            // Ensure it only work with anchors
            if (!event.target.matches('a.btn--filter')) {
                return
            }
            let filterValue = event.target.getAttribute('data-filter')
            filterValue = filterValue
            iso.arrange({filter: filterValue})
        })
    }

    // Change is-checked class on buttons
    let buttonGroups = document.querySelectorAll('.filters--list')
    for (let i = 0; i < buttonGroups.length; i++) {
        let buttonGroup = buttonGroups[i]
        radioButtonGroup(buttonGroup)
    }

    function radioButtonGroup(buttonGroup) {
        buttonGroup.addEventListener('click', (event) => {
            // Only work with anchors
            if (!event.target.matches('a.btn--filter')) {
                return
            }
            buttonGroup.querySelector('.active').classList.remove('active')
            event.target.classList.add('active')
        })
    }
}
