const { event } = require("jquery");

jQuery(($) => {		
    
    
    // Table on contents scroll links
    let scrollLinks  = $('.toc--links a');
    scrollLinks.each( (index, element) => {    
        $(element).on('click', (event) => {
            if (event.target.hash !== '') {
                let link = event.target.hash
                let heightFromTop = $(window).height() / 5;
                event.preventDefault()
                $('html, body').animate(
                    {
                        scrollTop: $(link).offset().top - heightFromTop
                    },
                    2000
                )
            }
        })
    });
})
