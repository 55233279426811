jQuery(($) => {
    
    let tabButtons = $('.block--tabbed-content .tab-content button');
    let tabs = $('.block--tabbed-content .tab-buttons button');
    let tabPanels = $('.block--tabbed-content .tab-content .card .card--panel'); 

    // Preventing the current open accordion from closing itself
    // $('[data-toggle="collapse"]').on('click',function(e){
    //     if ( $(this).parents('.accordion').find('.collapse.show') ){
    //         var idx = $(this).index('[data-toggle="collapse"]');
    //         if (idx == $('.collapse.show').index('.collapse')) {
    //             e.stopPropagation();
    //         }
    //     }
    // });

    //Toggles class from accordian cards for styling
    $(window).on('load resize', () => {
        
        if (window.innerWidth < 992 ) {
            for (i = 0; i < tabButtons.length; i++) {
                $(tabButtons[i]).attr("aria-expanded", false);
                $(tabs[i]).attr("aria-expanded", false);
                $(tabPanels[i]).removeClass("show");
            }
            if (tabButtons.length == 1) {
                $(tabButtons[0]).css({pointerEvents: "initial"});
                $(tabs[0]).css({pointerEvents: "intitial"});
            }
        } else if (window.innerWidth > 992 ) {
            
            for (i = 0; i < tabButtons.length; i++) {
                if (i == 0) {
                    $(tabButtons[i]).attr("aria-expanded", true);
                    $(tabs[i]).attr("aria-expanded",true);
                    $(tabPanels[i]).addClass("show");
                } else {
                    $(tabButtons[i]).attr("aria-expanded", false);
                    $(tabs[i]).attr("aria-expanded", false);
                    $(tabPanels[i]).removeClass("show");
                }
            }
            if (tabButtons.length == 1) {
                $(tabButtons[0]).css({pointerEvents: "none"});
                $(tabs[0]).css({pointerEvents: "none"});
            }
        }
    })
    
    //Toggles class from accordian cards for styling
    tabButtons.each( (index, element)=> {
        $(element).on('click', ()=>{
            if (window.innerWidth <= 992 ) {
                $(element).closest('.card').toggleClass('open');
                $(element).closest('.card').siblings('.card').removeClass('open');
            }
        })
    })
})
