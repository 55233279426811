jQuery(($) => {
    const announcementBar = $('#banner--announcement');
    const collapseButton = $('.banner--announcement__controls .button__collapse');
    const closeButton = $('.banner--announcement__controls .button__close');

    let cookieExistsAnnouncementBar = false

    cookieExistsAnnouncementBar = getCookie('announcement')
    if(cookieExistsAnnouncementBar) {
        $(announcementBar).addClass('d-none')
    }
    
    closeButton.on('click', (event) => {
        $(event.target).closest('.banner--announcement').addClass('d-none')
        setCookie('announcement', 'true', '30')
    })    

    collapseButton.on('click', (event) => {
        let button = $(event.target)
        
        if (announcementBar.css('maxHeight') != 'none') {
            announcementBar.css('maxHeight','initial');
            button.addClass('expanded');
        } else {
            announcementBar.css('maxHeight','');
            button.removeClass('expanded');
        }
    });
    
    //Sets cookies
    function setCookie(name, value, exdays) {
        let d = new Date()
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
        let expires = "expires="+d.toUTCString()
        document.cookie = name + "=" + value + ";" + expires + ";path=/"
    }
    
    //Retrieves cookies if they exist
    function getCookie(name) {
        let cookieName = name + "="
        let ca = document.cookie.split(';')
    
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i]
    
          while (c.charAt(0) == ' ') {
            c = c.substring(1)
          }
          if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length)
          }
        }
        return ""
    }
})
