jQuery(($) => {

    $numberFields = $('input[type="number"]').not('.input-text.qty.text');
    $numberFields.each( (index, element) => {
        let numberInput = $(element);
        numberInput.after(`<button class="btn--number btn--number-minus"><i class="fa fa-minus"></i></button>`);
        numberInput.after(`<button class="btn--number btn--number-plus"><i class="fa fa-plus"></i></button>`);
        
    });


    $(document).on('click', '.btn--number-plus', (event) => {
        event.preventDefault();
        
        let input = $(event.target).closest('.ginput_container_number').find('input[type="number"]');
        let inputValue = $(input).val();
        let maxVal = $(input).attr('max')
        let minVal = $(input).attr('min')

        if (inputValue == '') {
            input.val(parseInt(1));
        }
        
        if ((inputValue >= parseInt(minVal) ) && (inputValue < parseInt(maxVal))) {
            input.val(parseInt(inputValue) + 1);
        }
    });

    $(document).on('click', '.btn--number-minus', (event) => {
        event.preventDefault();
        
        let input = $(event.target).closest('.ginput_container_number').find('input[type="number"]');
        let inputValue = $(input).val();
        let maxVal = $(input).attr('max');
        let minVal = $(input).attr('min');
        
        if ((inputValue >  parseInt(minVal) ) && (inputValue <= parseInt(maxVal))) {
            input.val(parseInt(inputValue) - 1);
        }
    });
})


//Product category selector
let productTermSelector = document.getElementById("select--category__product");

if (productTermSelector != null) {
    function onChange() {
        if ( productTermSelector.options[productTermSelector.selectedIndex].value != '' ) {
            location.href = productTermSelector.options[productTermSelector.selectedIndex].value;
            console.log(productTermSelector.options[productTermSelector.selectedIndex].value);
        }   
    }
    productTermSelector.onchange = onChange;
}
